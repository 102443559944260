import { environment as Environment } from "../../../../../environments/environment";

export const API_URL = Environment.ApiURL || "https://develop-api.lema.dk/api";

/**
 * An Object that holds all the api endpoints of the backend
 * @type {Object}
 */
export const API = {
  API_URL: API_URL,
  me: `${API_URL}/me`,
  updateProfile: `${API_URL}/update-profile`,
  Authentication: {
    Login: `${API_URL}/auth`,
    verifyToken: `${API_URL}/me`,
    forgotPassword: `${API_URL}/forgot/password`,
    resetPassword: `${API_URL}/password/reset`,
    changePassword: `${API_URL}/auth/change-password`,
  },
  users: `${API_URL}/users`,
  customers: `${API_URL}/customers`,
  roles: `${API_URL}/roles`,
  permissions: `${API_URL}/permissions`,
  tickets: `${API_URL}/tickets`,
  csrfCookie: `${API_URL}/airlock/csrf-cookie`,
  search: `${API_URL}/search`,
  lookups: `${API_URL}/lookups`,
  dealerships: `${API_URL}/dealerships`,
  templates: `${API_URL}/document-templates`,
  vehiclesMakes: `${API_URL}/assets/vehicles-makes`,
  vehiclesModels: `${API_URL}/assets/vehicles-models`,
  vehicles: `${API_URL}/assets/vehicles`,
  bilbasen: `${API_URL}/bilbasen`,
  products: `${API_URL}/products`,
  settings: `${API_URL}/settings`,
  customSettings: `${API_URL}/custom-settings`,
  CVRData: `${API_URL}/get-cvr-data`,
  insurancePrices: `${API_URL}/insurance-prices`,
  contracts: `${API_URL}/contracts`,
  calculators: `${API_URL}/calculators`,
  fundingRates: `${API_URL}/funding-rates`,
  registrationTax: `${API_URL}/registration-settings`,
  registrationInterestRates: `${API_URL}/registration-interest-rates`,
  contractInvoice: `${API_URL}/contract-invoice`,
  vehiclePrepareInvoice: `${API_URL}/assets/vehicles/prepare-invoice`,
  vehiclePayments: `${API_URL}/payments/vehicles`,
  vehicleInvoices: `${API_URL}/invoices/vehicles`,
  payments: `${API_URL}/payments`,
  transactions: `${API_URL}/transactions`,
  transactionSets: `${API_URL}/transaction-sets`,
  contractAmortization: `${API_URL}/contract-amortization`,
  departments: `${API_URL}/departments`,
  risk_classification: `${API_URL}/risk-classification`,
  tasks: `${API_URL}/tasks`,
  suppliers: `${API_URL}/suppliers`,
  entities: `${API_URL}/entities`,
  offers: `${API_URL}/offers`,
  userTasks: `${API_URL}/user-tasks`,
  contractAccounts: `${API_URL}/contract-accounts`,
  contractMileage: `${API_URL}/contract-mileage`,
  Notes: `${API_URL}/notes`,
  FAQs: `${API_URL}/faqs`,
  Currencies: `${API_URL}/currencies`,
  MESSAGES: {
    patch_note: `${API_URL}/messages`,
    ANNOUNCEMENT: `${API_URL}/messages/announcements`,
  },
};

export const PATHS = {
  ValuationSource: `${API.API_URL}/valuation-source`,
  ValuationTypes: `${API.API_URL}/valuation-type`,
  InsuranceCompany: `${API.API_URL}/insurance-companies`,
  VehicleTypes: `${API.API_URL}/vehicle-types`,
  VehicleConditions: `${API.API_URL}/vehicle-conditions`,
  NoteCategories: `${API.API_URL}/note-categories`,
};
